import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppThemeIcon',
  className: 'app-theme',
  defaultAttrs: {
    viewBox: '0 0 24 24'
  },
  pathAttrs: [
    {
      d: 'm12.00001,1.13605l-6.83539,6.36396c-3.77504,3.51472 -3.77504,9.21322 0,12.72792c3.77515,3.5147 9.89568,3.5147 13.67074,0c3.77506,-3.5147 3.77506,-9.2132 0,-12.72792l-6.83535,-6.36396zm0,2.82843l5.31639,4.94975c2.93616,2.7337 2.93616,7.1658 0,9.8995c-1.46921,1.3679 -3.39519,2.0513 -5.3209,2.0502l0.00451,-16.89945z'
    }
  ]
})
