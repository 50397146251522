import config from '@enums/config'
import views from '@enums/views'

const TheValidate = () =>
  import(/* webpackChunkName: "the-validate" */ '@views/profile/TheValidate.vue')

export default [
  {
    path: '/validate',
    name: views.VALIDATE,
    component: TheValidate,
    beforeEnter: (to, from, next) => {
      const { id, token } = to.query
      if (id && token) return next()
      return next({ name: views.LOGIN })
    },
    props: route => ({
      id: route.query.id,
      token: route.query.token
    }),
    meta: {
      layout: config.LAYOUT_FULL,
      public: true
    }
  }
]
