import axios from '@axios'
import endpoints from '@enums/endpoints'
import apiTokenStatus from '@enums/apiTokenStatus'

/** @typedef {import('../store/modules/table').TableFiltersState} TableFiltersState */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */
/** @typedef {import('../store/modules/apiTokens').ApiToken} ApiToken */

export default {
  /**
   * Fetch api tokens values
   * @param {TableFiltersState} params
   * @returns {AxiosPromise}
   */
  getApiTokens (params) {
    return axios.get(endpoints.API_TOKENS, { params })
  },
  /**
   * Create new token
   * @param {String} name
   * @returns {AxiosPromise}
   */
  createApiToken (name) {
    return axios.post(endpoints.API_TOKENS, { name })
  },
  /**
   * Revoke a token
   * @param {String} apiTokenIRI
   * @returns {AxiosPromise}
   */
  revokeApiToken (apiTokenIRI) {
    return axios.patch(apiTokenIRI, {
      status: apiTokenStatus.REVOKED
    })
  }
}
