export const state = {
  items: undefined,
  loading: false
}

export const getters = {}

export const mutations = {
  set (state, { key, value }) {
    state[key] = value
  }
}

/** @typedef {import('vuex').ActionContext<State>} ActionContext - Action context */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */

export const actions = {
  /**
   * Fetch items values
   * @param {ActionContext} context
   * @returns {AxiosPromise}
   */
  async fetchItems ({ commit }) {
    try {
      commit('set', {
        key: 'loading',
        value: true
      })
      const response = await this.$services.rolesService.getRoles({ pagination: false })
      commit('set', {
        key: 'items',
        value: response?.data?.['hydra:member'] ?? undefined
      })

      return response
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('set', {
        key: 'loading',
        value: false
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
