import views from '@enums/views'
import analytics from '@enums/analytics'
import { AbilityBuilder, Ability } from '@casl/ability'
import Vue from 'vue'
import isObject from '@utils/isObject'
import clone from 'just-clone'

/**
 * @typedef {Object} Credentials - Credentials submitted by the login form
 * @prop {string} email - User email
 * @prop {string} password - User password
 */

/** @typedef {import('./user').User} User */

export const state = {}

export const getters = {
  isAuthenticated (state, getters, rootState) {
    return !!rootState.user?.userData
  }
}

export const mutations = {}

/**
 * Extract user login infos without rememberMyEmail property
 * @param {Credentials} [credentials]
 */
const getLogin = credentials => {
  if (!credentials) return undefined
  const { rememberMyEmail, ...login } = credentials
  return login
}

/** @typedef {import('vuex').ActionContext<State>} ActionContext - Action context */

export const actions = {
  /**
   * Call the {@link authenticate} function
   * Call the authentication endpoint if the use credentials are provided,
   * otherwise it retrieve the stored jwt and use it for subsequent requests.
   * It then set the fetched user and email preference and redirect to the home page
   * If the authentication process fail, it call the logout action.
   * @param {ActionContext} context - Action context
   * @param {Credentials} [credentials]
   * @return {Promise<User>} Current user
   */
  async authenticate (
    {
      rootState, rootGetters, commit, dispatch
    },
    credentials
  ) {
    try {
      const login = getLogin(credentials)
      const currentUser = await this.$auth.authenticate(login)

      // Clear specific store modules cache
      const modules = [
        'accounts',
        'adsTxt',
        'apiTokens',
        'bidders',
        'organizations',
        'organizationsAccounts',
        'organizationsWebsites',
        'pendingTagActivation',
        'unpublishedDraft',
        'websites',
        'websiteConfigsList'
      ]
      modules.forEach(module => {
        commit(`${module}/setItems`, undefined, { root: true })
      })

      commit('organizations/setAllItems', undefined, { root: true })

      const staticModules = ['roles', 'websiteProducts']
      staticModules.forEach(module => {
        commit(`${module}/set`, {
          key: 'items',
          value: undefined
        }, { root: true })
      })

      // Set user data
      commit('user/setUser', currentUser, { root: true })
      commit('user/setRememberMyEmail', credentials, { root: true })

      // Set currency preference
      commit('analytics/set', {
        key: analytics.CURRENCY,
        value: currentUser?.[analytics.CURRENCY]
      }, { root: true })

      // Fetch ACL values
      const { data: { 'hydra:member': policies } } = await this.$services.casbinPoliciesService.getPolicies({
        pagination: false
      })
      // Set ACL permissions
      commit('user/setPermissions', {
        permissions: policies
      }, { root: true })

      // ACL permissions
      const userPermissions = clone(rootState.user.permissions)
      /**
       * @casl lib https://www.npmjs.com/package/@casl/ability
       * @returns {Ability}
       */
      const defineAbilitiesFor = () => {
        const { can, rules } = new AbilityBuilder(Ability)

        Object.keys(userPermissions).map(action => userPermissions[action].map(object => {
          // No fields in object
          if (!isObject(object)) return can(action, object)

          // Fields in object
          return Object.keys(object).map(item => can(action, item, object[item]))
        }))

        return new Ability(rules)
      }

      const ability = defineAbilitiesFor()
      Vue.prototype.$can = (action, object, field) => (action && object && !rootGetters['user/isSuperAdmin']
        ? ability.can(action, object, field)
        : true)

      return currentUser
    } catch (error) {
      await dispatch('clearUser')
      return Promise.reject(error)
    }
  },
  /**
   * Call the auth {@link logout} function
   * then remove the current userData by calling the setUser mutation
   * @param {ActionContext} context - Action context
   */
  clearUser ({ commit }) {
    this.$auth.logout()
    commit('user/setUser', undefined, { root: true })
  },
  /**
   * User logout
   * Call the clearUser action then redirect to the login route
   * if it's not the current one
   * @param {ActionContext} context - Action context
   * @param {boolean} [expiredJWTToken=false]
   */
  logout ({ dispatch }, expiredJWTToken = false) {
    dispatch('clearUser')
    if (this.$router.currentRoute.name === views.LOGIN) return undefined
    return this.$router
      .push({
        name: views.LOGIN,
        query: expiredJWTToken === true ? { jwt: 'expired' } : null
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
