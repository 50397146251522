import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppBidderIcon',
  className: 'app-bidder',
  defaultAttrs: {
    viewBox: '0 0 24 24'
  },
  pathAttrs: [
    {
      d: 'm20.07967,15.39967c1.69491,-4.01387 0.17365,-8.75544 -3.69724,-10.99027c-2.7972,-1.61497 -6.11459,-1.51287 -8.73165,-0.02071l-1.08724,-1.90286c3.27131,-1.86516 7.41797,-1.99279 10.9145,0.02592c4.92005,2.84056 6.8034,8.92083 4.51117,13.99043l1.47009,0.84877l-4.56332,2.42579l-0.18078,-5.16481l1.36447,0.78774zm-16.15934,-6.79937c-1.69488,4.01389 -0.1736,8.75547 3.69724,10.99029c2.79719,1.61493 6.11447,1.51293 8.73144,0.02082l1.08728,1.90285c-3.27127,1.86505 -7.41782,1.99269 -10.91433,-0.02597c-4.91998,-2.84059 -6.80335,-8.92089 -4.51116,-13.99046l-1.47009,-0.84876l4.56329,-2.42579l0.18084,5.16481l-1.36452,-0.7878zm4.24505,5.59091l6.02584,0c0.30261,0 0.5478,-0.24531 0.5478,-0.5478c0,-0.3025 -0.2452,-0.5478 -0.5478,-0.5478l-4.38244,0c-1.51271,0 -2.73901,-1.22631 -2.73901,-2.73902c0,-1.51271 1.2263,-2.73901 2.73901,-2.73901l1.09561,0l0,-1.09561l2.19122,0l0,1.09561l2.73902,0l0,2.19121l-6.02585,0c-0.30253,0 -0.54779,0.24531 -0.54779,0.5478c0,0.3025 0.24526,0.5478 0.54779,0.5478l4.38244,0c1.51271,0 2.73902,1.22631 2.73902,2.73902c0,1.51271 -1.22631,2.73902 -2.73902,2.73902l-1.09561,0l0,1.09561l-2.19122,0l0,-1.09561l-2.73901,0l0,-2.19122z'
    }
  ]
})
