import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import configStatus from '@enums/configStatus'

export const state = {
  service: {
    name: 'websiteConfigsService',
    getItemsMethod: 'getWebsiteConfigs'
  },
  ...stateTable,
  tableColumnFilters: {
    status: configStatus.DRAFT
  }
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
