import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { createVuexHelpers } from 'vue2-helpers'
import analyticsEnums from '@enums/analytics'

// Modules
import accounts from './modules/accounts'
import aclRules from './modules/aclRules'
import adsTxt from './modules/adsTxt'
import analytics from './modules/analytics'
import apiTokens from './modules/apiTokens'
import app from './modules/app'
import appConfig from './modules/appConfig'
import auth from './modules/auth'
import bidders from './modules/bidders'
import biddersWebsiteConfig from './modules/biddersWebsiteConfig'
import countries from './modules/countries'
import currencies from './modules/currencies'
import genders from './modules/genders'
import organizations from './modules/organizations'
import organizationsAccounts from './modules/organizationsAccounts'
import organizationTypes from './modules/organizationTypes'
import organizationsWebsites from './modules/organizationsWebsites'
import pendingTagActivation from './modules/pendingTagActivation'
import roles from './modules/roles'
import unpublishedDraft from './modules/unpublishedDraft'
import user from './modules/user'
import verticalMenu from './modules/verticalMenu'
import websites from './modules/websites'
import websiteConfig from './modules/websiteConfig'
import websiteConfigsList from './modules/websiteConfigsList'
import websiteProducts from './modules/websiteProducts'

Vue.use(Vuex)

const storageName = process.env.VUE_APP_STORAGE_NAME
const storage = localStorage

const vuexPersist = new VuexPersistence({
  key: storageName,
  strictMode: process.env.VUE_APP_ENVIRONMENT !== 'production',
  storage,
  reducer: state => ({
    user: {
      rememberMyEmail: state.user?.rememberMyEmail,
      rememberedEmail: state.user?.rememberedEmail
    },
    analytics: {
      [analyticsEnums.TIMEZONE]: state.analytics[analyticsEnums.TIMEZONE]
    },
    verticalMenu: {
      isVerticalMenuCollapsed: state.verticalMenu?.isVerticalMenuCollapsed
    }
  })
})

const vuexPersistSession = new VuexPersistence({
  key: storageName,
  strictMode: process.env.VUE_APP_ENVIRONMENT !== 'production',
  storage: sessionStorage,
  reducer: state => ({
    websites: {
      websitesScraping: state.websites.websitesScraping
    }
  })
})

export default new Vuex.Store({
  modules: {
    accounts,
    aclRules,
    adsTxt,
    analytics,
    apiTokens,
    app,
    appConfig,
    auth,
    bidders,
    biddersWebsiteConfig,
    countries,
    currencies,
    genders,
    organizations,
    organizationsAccounts,
    organizationTypes,
    organizationsWebsites,
    pendingTagActivation,
    roles,
    unpublishedDraft,
    user,
    verticalMenu,
    websites,
    websiteConfig,
    websiteConfigsList,
    websiteProducts
  },
  strict: process.env.VUE_APP_ENVIRONMENT !== 'production',
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
    setStoreInitialization (state) {
      // Check if item exist
      if (storage.getItem(storageName)) {
        // Replace the state object with the stored item
        this.replaceState(Object.assign(state, JSON.parse(storage.getItem(storageName))))
      }
    }
  },
  plugins: [vuexPersist.plugin, vuexPersistSession.plugin]
})

// Vuex helpers
export const {
  useState, useGetters, useMutations, useActions
} = createVuexHelpers()
