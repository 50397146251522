import axios from '@axios'
import endpoints from '@enums/endpoints'

/** @typedef {import('axios').AxiosPromise} AxiosPromise */

/**
 * @typedef {Object} UserGroups
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {Array} hydraMember - as hydra:member
 * @prop {string} hydraMember.id - as hydra:member.@id
 * @prop {string} hydraMember.type - as hydra:member@type
 * @prop {Array} hydraMember.role - as hydra:member.role
 * @prop {string} hydraMember.organization - as hydra:member.organization
 * @prop {string} hydraMember.user - as hydra:member.user
 * @prop {string} hydraMember.website - as hydra:member.website
 * @prop {number} hydraTotalItems - as hydra:totalItems
 * @prop {Object} hydraSearch - as hydra:search
 */

/**
 * @typedef {Object} UserGroup
 * @prop {Array} role
 * @prop {string} user
 * @prop {string} organization
 * @prop {string} website
 */

export default {
  /**
   * Fetch userGroups values
   * @param {Object} params
   * @returns {AxiosPromise}
   */
  getUserGroups (params) {
    return axios.get(endpoints.USER_GROUPS, { params })
  },
  /**
   * Create user group
   * @param {UserGroup} data
   * @returns {AxiosUserResponse}
   */
  createUserGroups (data) {
    return axios.post(endpoints.USER_GROUPS, data)
  },
  /**
   * Delete user group
   * @param {string} userGroupsIRI
   * @returns {AxiosPromise}
   */
  deleteUserGroups (userGroupsIRI) {
    return axios.delete(userGroupsIRI)
  }
}
