import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'

/**
 * @typedef {Object} Acls
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {array} hydraMember - as hydra:member
 * @prop {string} hydraMember.id - as hydra:member.@id
 * @prop {string} hydraMember.type - as hydra:member.@type
 * @prop {string} hydraMember.subject - as hydra:member.subject
 * @prop {string} hydraMember.object - as hydra:member.object
 * @prop {string} hydraMember.action - as hydra:member.action
 * @prop {string} hydraMember.field - as hydra:member.field
 * @prop {number} hydraTotalItems - as hydra:totalItems
 * @prop {object} hydraSearch - as hydra:search
 */

/**
 * @typedef {Object} Acl
 * @prop {string} subject
 * @prop {string} object
 * @prop {string} action
 * @prop {string} field
 */

/** @typedef {import('./modules/table').TableFiltersState} TableFiltersState */

/**
 * @typedef {Object} State
 * @prop {object} service
 * @prop {Acl[]} [items]
 * @prop {number} totalItems
 * @prop {TableFiltersState} tableFiltersState
 */

export const state = {
  service: {
    name: 'aclRulesService',
    getItemsMethod: 'getAclRules'
  },
  ...stateTable
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
