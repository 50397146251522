import axios from '@axios'
import endpoints from '@enums/endpoints'

/** @typedef {import('axios').AxiosPromise} AxiosPromise */

/**
 * @typedef {Object} Roles
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {Array} hydraMember - as hydra:member
 * @prop {string} hydraMember.id - as hydra:member.@id
 * @prop {string} hydraMember.type - as hydra:member@type
 * @prop {string} hydraMember.name - as hydra:member.name
 * @prop {string} hydraMember.label - as hydra:member.label
 * @prop {number} hydraTotalItems - as hydra:totalItems
 * @prop {Object} hydraSearch - as hydra:search
 */

export default {
  /**
   * Fetch roles values
   * @param {Object} params
   * @returns {AxiosPromise}
   */
  getRoles (params) {
    return axios.get(endpoints.ROLES, { params })
  }
}
