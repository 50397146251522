import {
  stateAPI, gettersAPI, mutationsAPI, actionsAPI
} from '@store/modules/api'
import endpoints from '@enums/endpoints'

export const state = {
  endpoint: endpoints.GENDERS,
  ...stateAPI
}

export const getters = {
  ...gettersAPI
}

export const mutations = {
  ...mutationsAPI
}

export const actions = {
  ...actionsAPI
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
