// TODO for API V1
import endpoints from '@enums/endpoints'
import statusCode from '@enums/statusCode'
import axios from '@/libs/axios-api-v1'

export const state = {
  items: undefined,
  loading: false
}

export const getters = {}

export const mutations = {
  set (state, { key, value }) {
    state[key] = value
  }
}

/** @typedef {import('vuex').ActionContext<State>} ActionContext - Action context */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */

export const actions = {
  /**
   * Fetch items values
   * @param {ActionContext} context
   * @returns {AxiosPromise}
   */
  async fetchItems ({ commit, dispatch }) {
    try {
      commit('set', {
        key: 'loading',
        value: true
      })
      const { data } = await axios.get(endpoints.CURRENCIES)
      commit('set', {
        key: 'items',
        value: data?.datas || undefined
      })

      return data
    } catch (error) {
      // Redirect to login page when request fail with a 498 response
      if (error?.response?.status === statusCode.TOKEN_EXPIRED) {
        await dispatch('auth/logout', true, { root: true })
      }
      return Promise.reject(error)
    } finally {
      commit('set', {
        key: 'loading',
        value: false
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
