import isObject from '@utils/isObject'
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'
import config from '@enums/config'

/**
 * @typedef {Object} Credentials - Credentials submitted by the login form
 * @prop {string} email - User email
 * @prop {string} password - User password
 * @prop {boolean} rememberMyEmail - Remember my email option
 */

/**
 * @typedef {Object} LocalUser
 * @prop {boolean} active
 * @prop {string} email
 * @prop {string} firstName
 * @prop {string} gender
 * @prop {string} lastName
 * @prop {string} phoneNumber
 * @prop {string} plainPassword
 * @prop {Array<string>} [roles]
 */

/**
 * @typedef {Object} APIUserOnly
 * @prop {string} context - Start with @
 * @prop {string} id - Start with @
 * @prop {string} type - Start with @
 * @prop {string} createdAt
 * @prop {string} lastLoginAt
 */

/**
 * @typedef {LocalUser & APIUserOnly} APIUser
 */

/** @typedef {import('/modules/user').LocalUser} User */
/** @typedef {import('../../services/userGroups').userGroups} userGroups */

/**
 * @typedef {Object} State
 * @prop {APIUser} [userData]
 * @prop {string} defaultRole
 * @prop {boolean} rememberMyEmail
 * @prop {string} rememberedEmail
 * @prop {Object} permissions
 * @prop {Array} roles
 */

/** @type {State} */
export const state = {
  userData: undefined,
  defaultRole: 'Customer',
  rememberMyEmail: true,
  rememberedEmail: '',
  permissions: {}
}

export const getters = {
  /**
   * @param {State} state
   */
  fullName (state) {
    if (!isObject(state.userData)) {
      return ''
    }
    return `${state.userData.firstName} ${state.userData.lastName}`
  },
  /**
   * @param {State} state
   */
  userRoles (state) {
    const userRoles = state.userData?.userGroups
    return userRoles && userRoles.length ? userRoles.map(userRole => userRole?.role?.label) : [state.defaultRole]
  },
  /**
   * @param {State} state
   */
  isSuperAdmin (state) {
    const userRoles = state.userData?.userGroups
    if (!userRoles || userRoles.length === 0) return false

    return userRoles
      .map(userRole => userRole?.role?.name)
      .includes(config.ROLES.superAdmin)
  }
}

export const mutations = {
  /**
   * @param {State} state
   * @param {APIUser} [user]
   */
  setUser (state, user) {
    state.userData = user
  },
  /**
   * @param {State} state
   * @param {Credentials} [credentials]
   */
  setRememberMyEmail (state, credentials) {
    if (!credentials) return

    const { rememberMyEmail, email } = credentials || {}

    if (rememberMyEmail === undefined) return

    state.rememberMyEmail = rememberMyEmail
    state.rememberedEmail = rememberMyEmail ? email : ''
  },
  /**
   * @param {State} state
   * @param {Object} permissions
   */
  setPermissions (state, { permissions: casbinPolicies }) {
    const userPermissions = {
      [aclActions.CREATE]: [],
      [aclActions.DELETE]: [],
      [aclActions.GET]: [],
      [aclActions.LIST]: [],
      [aclActions.PUBLISH]: [],
      [aclActions.RESEND]: [],
      [aclActions.UPDATE]: []
    }

    /**
     * Permissions hydration
     * @param {string} action
     * @param {string} object
     * @param {string} field
     * @param {string|undefined} [value=undefined]
     */
    const getPermissions = (action, object, field, value = undefined) => {
      // Check if action is already set, if not create entry
      if (!(action in userPermissions)) userPermissions[action] = []
      // Check if object is already set, if not create entry
      const actionValues = Object.values(userPermissions[action])
      if (!actionValues.length) {
        userPermissions[action].push({ [object]: [] })
        // Add field and value
        const fieldvalue = value ? `${field}:${value}` : field
        userPermissions[action]
          .find(obj => Object.keys(obj).includes(object))[object]
          .push(fieldvalue)
      } else {
        const containsObject = actionValues.findIndex(obj => Object.keys(obj).includes(object)) !== -1
        if (!containsObject) userPermissions[action].push({ [object]: [] })
        // Add field and value
        const fieldvalue = value ? `${field}:${value}` : field
        userPermissions[action]
          .find(obj => Object.keys(obj).includes(object))[object]
          .push(fieldvalue)
      }
    }

    // Loop with field in permission
    casbinPolicies.forEach(policie => {
      if (policie?.pType === 'p2') {
        const action = policie?.v3
        const object = policie?.v1
        const field = policie?.v2
        getPermissions(action, object, field)
      }

      if (policie?.pType === 'p3') {
        const action = policie?.v2
        const object = policie?.v1
        const field = policie?.v3
        const value = policie?.v4
        getPermissions(action, object, field, value)
      }
    })

    const actionWithRestrictingFields = [
      aclActions.CREATE,
      aclActions.GET,
      aclActions.LIST,
      aclActions.UPDATE
    ]

    const objectWithRestrictingFields = [
      aclObjects.ORGANIZATION,
      aclObjects.USER,
      aclObjects.USER_GROUP,
      aclObjects.WEBSITE,
      aclObjects.WEBSITE_CONFIG
    ]

    // Loop with no field in permission
    casbinPolicies.forEach(policie => {
      if (policie?.pType === 'p') {
        const action = policie?.v2
        const object = policie?.v1
        // Check if action is already set, if not create entry
        if (!(action in userPermissions)) userPermissions[action] = []
        // Check if object is already set, if not create entry
        const actionValues = Object.values(userPermissions[action])
        const containsObject = actionValues.findIndex(obj => Object.keys(obj).includes(object)) !== -1
        if (!containsObject) {
          if (objectWithRestrictingFields.includes(object) && actionWithRestrictingFields.includes(action)) {
            userPermissions[action].push({ [object]: ['has_restricted_fields'] })
          } else {
            userPermissions[action].push(object)
          }
        }
      }
    })

    state.permissions = userPermissions
  }
}

/** @typedef {import('vuex').ActionContext<State>} ActionContext - Action context */
/** @typedef {import('../../services/usersService').UpdateEmailPayload} UpdateEmailPayload */

export const actions = {
  /**
   * Send a PATCH request and update user stored in state with the request response
   * @param {ActionContext} actionContext
   * @param {Object} userData
   * @param {string} userData.userIRI
   * @param {Partial<APIUser>} userData.data
   * @returns {Promise<APIUser>}
   */
  async patchUser ({ commit, state }, { userIRI, data }) {
    const { data: patchedUser } = await this.$services.usersService.updateUser(
      userIRI,
      data
    )
    commit('setUser', patchedUser)
    return state.userData
  },
  /**
   * Send a POST request and update user stored in state with the new email
   * @param {ActionContext} actionContext
   * @param {UpdateEmailPayload} payload
   */
  async updateEmail ({ commit, state }, payload) {
    await this.$services.usersService.updateEmail(payload)
    commit('setUser', {
      ...state.userData,
      email: payload.email
    })

    commit('setRememberMyEmail', {
      rememberMyEmail: state.rememberMyEmail,
      email: payload.email
    })
    return state.userData
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
