import { defineAbility } from '@casl/ability'
import store from '@store'
import isObject from '@utils/isObject'

// eslint-disable-next-line consistent-return
export const useAbility = () => defineAbility(can => {
  if (store.getters['user/isSuperAdmin']) {
    // For super admin only, we can use this rule too => ability.can(aclActions.ACTION, aclObjects.ANY)
    return can('manage', 'all')
  }

  const { permissions } = store.state.user

  Object.keys(permissions).map(action => permissions[action].map(object => {
    // No fields in object
    if (!isObject(object)) return can(action, object)

    // Fields in object
    return Object.keys(object).map(item => can(action, item, object[item]))
  }))
})

export const _ = null
