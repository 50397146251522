import views from '@enums/views'
import store from '@store'
import statusCode from '@enums/statusCode'
import endpoints from '@enums/endpoints'
import config from '@enums/config'
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'

const AdsTxtList = () =>
  import(/* webpackChunkName: "ads-txt-list" */ '@views/ads-txt/AdsTxtList.vue')
const AdsTxtTabs = () =>
  import(/* webpackChunkName: "ads-txt-tabs" */ '@views/ads-txt/AdsTxtTabs.vue')
const AdsTxtManager = () =>
  import(/* webpackChunkName: "ads-txt-manager" */ '@views/ads-txt/AdsTxtManager.vue')

const appBreadcrumb = [
  {
    text: 'adsTxt',
    to: { name: views.ADS_TXT }
  },
  {
    text: () => store.getters['adsTxt/bidderName'],
    active: true
  }
]

export default [
  {
    path: `/${views.ADS_TXT}`,
    name: views.ADS_TXT,
    component: AdsTxtList,
    beforeEnter: async (to, from, next) => {
      try {
        // Fetch bidders values
        const { data: { 'hydra:member': bidders } } = await store.$services.biddersService.getBidders({
          pagination: false,
          exists: {
            defaultAdstxt: true
          }
        })
        store.commit('adsTxt/setBidderItems', bidders)
        next()
      } catch (error) {
        // eslint-disable-next-line no-console
        if (config.ENVIRONMENT === 'development') console.info(error?.message)
        next()
      }
    },
    meta: {
      appBreadcrumb: [
        {
          text: 'adsTxt',
          active: true
        }
      ],
      action: aclActions.LIST,
      object: aclObjects.DEFAULT_ADSTXT
    }
  },
  {
    path: `/${views.ADS_TXT}/create`,
    name: views.ADS_TXT_CREATE,
    component: AdsTxtManager,
    props: {
      action: 'create'
    },
    meta: {
      appBreadcrumb: [
        {
          text: 'adsTxt',
          to: { name: views.ADS_TXT }
        },
        {
          text: 'create',
          active: true
        }
      ],
      action: aclActions.CREATE,
      object: aclObjects.DEFAULT_ADSTXT
    }
  },
  {
    path: `/${views.ADS_TXT}/edit/:id(\\d+)`,
    redirect: { name: views.ADS_TXT_EDIT_GENERAL },
    name: views.ADS_TXT_EDIT,
    component: AdsTxtTabs,
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('adsTxt/fetchItem', `${endpoints.ADS_TXT}/${to.params.id}`)
        await store.dispatch('bidders/fetchItem', store.state.adsTxt.editedItem.bidder)
        store.commit('adsTxt/setBidderName', store.state.bidders.editedItem.name)
        next()
      } catch (error) {
        error?.response?.status === statusCode.UNAUTHORIZED
          ? next(false)
          : next({
            name: views.RESOURCE_NOT_FOUND,
            params: { target: views.ADS_TXT_EDIT }
          })
      }
    },
    children: [
      {
        path: '',
        name: views.ADS_TXT_EDIT_GENERAL,
        component: AdsTxtManager,
        meta: {
          appBreadcrumb,
          action: aclActions.GET,
          object: [aclObjects.WEBSITE, aclObjects.ALL_WEBSITES]
        }
      }
    ]
  }
]
