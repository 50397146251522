import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppAnalyticsIcon',
  className: 'app-analytics',
  defaultAttrs: {
    viewBox: '0 0 100 100'
  },
  pathAttrs: [
    {
      d: 'M41.917 5h15v90h-15ZM72.417 25.333h15v70h-15ZM12.583 45h15v50h-15V45z'
    }
  ]
})
