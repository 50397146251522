import bidders from '@fixtures/bidders'
import biddersTemplates from '@fixtures/bidders-templates.json'
import biddersSsp from '@fixtures/bidders-ssp'
import biddersSspTemplates from '@fixtures/bidders-ssp-templates.json'

export const state = {}

export const getters = {
  /**
   * Get bidders
   * @returns {Array}
   */
  bidders: () => bidders,
  /**
   * Get ssp bidders
   * @returns {Array}
   */
  biddersSsp: () => biddersSsp,
  /**
   * Get bidders templates
   * @returns {Array}
   */
  biddersTemplates: () => biddersTemplates,
  /**
   * Get bidders ssp templates
   * @returns {Array}
   */
  biddersSspTemplates: () => biddersSspTemplates,
  /**
   * Return the bidder associated with a bidder
   * @param {State} state
   * @param {Getters} getters
   * @param {Object} bidder
   * @param {boolean} [flashbidProduct=true]
   * @returns {Function}
   */
  getBidder: (state, getters) => (bidder, flashbidProduct = true) => {
    const targetBidder = flashbidProduct ? getters.bidders : getters.biddersSsp
    return targetBidder.find(({ id, label }) => id === bidder.id || label === bidder.name)
  },
  /**
   * Return the bidder template associated with a bidder
   * @param {State} state
   * @param {Getters} getters
   * @param {Object} bidder
   * @param {boolean} [flashbidProduct=true]
   * @param {string} [organization='']
   * @returns {Function}
   */
  getBidderTemplate: (state, getters) => (bidder, flashbidProduct = true) => {
    const targetTemplates = flashbidProduct ? getters.biddersTemplates : getters.biddersSspTemplates
    return targetTemplates.find(({ id }) => id === bidder.target)
  }
}

export const mutations = {}

export const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
