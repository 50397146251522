import views from '@enums/views'
import config from '@enums/config'

const ProfileRegister = () =>
  import(/* webpackChunkName: "profile-register" */ '@views/profile/ProfileRegister.vue')
const ProfileForgotPassword = () =>
  import(
    /* webpackChunkName: "profile-forgot-password" */ '@views/profile/ProfileForgotPassword.vue'
  )
const ProfileResetPassword = () =>
  import(
    /* webpackChunkName: "profile-reset-password" */ '@views/profile/ProfileResetPassword.vue'
  )

export default [
  {
    path: `/${views.REGISTER}`,
    name: views.REGISTER,
    component: ProfileRegister,
    props: route => ({
      invitation: route.query?.invitation
    }),
    meta: {
      layout: config.LAYOUT_FORM_PAGE,
      title: 'profile.adventure',
      icon: '🚀',
      subtitle: 'profile.adventureInfo',
      publicOnly: true
    }
  },
  {
    path: `/${views.FORGOT_PASSWORD}`,
    name: views.FORGOT_PASSWORD,
    component: ProfileForgotPassword,
    meta: {
      layout: config.LAYOUT_FORM_PAGE,
      title: 'profile.forgotPassword',
      icon: '🔒',
      subtitle: 'profile.forgotPasswordInfo',
      publicOnly: true
    }
  },
  {
    path: `/${views.RESET_PASSWORD}`,
    name: views.RESET_PASSWORD,
    component: ProfileResetPassword,
    beforeEnter (to, from, next) {
      // Redirect to login page if not required url params
      to.query.id && to.query.token ? next() : next({ name: views.LOGIN })
    },
    props: route => ({
      id: route.query.id,
      token: route.query.token
    }),
    meta: {
      layout: config.LAYOUT_FORM_PAGE,
      title: 'profile.changePassword',
      icon: '🔒',
      subtitle: 'profile.resetPasswordInfo',
      publicOnly: true
    }
  }
]
