import jsonwebtoken from 'jsonwebtoken'
import Cookies from 'js-cookie'
import config from '@enums/config'
import endpoints from '@enums/endpoints'
import axios, { setAxiosHeader, removeAxiosHeader } from '@axios'
import { setAxiosHeaderApiV1 } from '@/libs/axios-api-v1'

/** @typedef {{email: string, password: string}} Credentials - Credentials used for login */

/** @typedef {string} JWT - JSON Web Token */

/**
 * @typedef {Object} JWTPayload - JSON Web Token payload
 * @prop {number} exp - Expiration Time Claim
 * @prop {string} iri - IRI of the authenticated user
 */

/** @typedef {{jwt: JWT, jwtPayload: JWTPayload}} JWTWithPayload */

/** @typedef {import('../store/modules/user').User} User */

/**
 * Fetch the jwt by calling the auth endpoint with the credential payload
 * @param {Credentials} credentials
 */
export const fetchJWT = credentials => axios.post(endpoints.AUTH_TOKEN, credentials)

// Retrieve JWT in cookie
export const getJWT = () => Cookies.get(config.CROSS_JWT)

// Config for cross-jwt cookie for path in analytics and platform
const cookieConfig = {
  path: '/',
  domain: process.env.VUE_APP_COOKIE_DOMAIN,
  expires: 1,
  sameSite: 'None',
  secure: true
}

/**
 * Set JWT in cookie
 * @param {JWT} jwt
 */
export const setJWT = jwt => Cookies.set(config.CROSS_JWT, jwt, cookieConfig)

// Remove JWT from cookie
export const removeJWT = () => Cookies.remove(config.CROSS_JWT, cookieConfig)

/**
 * Get formatted authorization header
 * @param {JWT} jwt
 */
export const getAuthorizationHeader = jwt => `${config.TOKEN_TYPE} ${jwt}`

/**
 * Verify if the jwt is valid by checking the expiration date
 * @param {JWTPayload} jwtPayload
 * @returns {boolean} Is the jwt expired
 */
export const checkJWTExpiration = jwtPayload => Date.now() / 1000 > jwtPayload.exp

/**
 * Retrieve te jwt stored in localStorage and check its validity
 * @throws No JWT found in storage
 * @throws Found JWT has expired
 * @returns {JWTWithPayload} Valid jwt with its payload
 */
export const retrieveValidJWT = () => {
  const jwt = getJWT()
  if (!jwt) throw new Error('No JWT found in storage')
  const jwtPayload = jsonwebtoken.decode(jwt)
  const hasJWTExpired = checkJWTExpiration(jwtPayload)
  if (hasJWTExpired) throw new Error('Found JWT has expired')

  return {
    jwt,
    jwtPayload
  }
}

/**
 * Retrieve valid stored JWT or call auth endpoint with provided credentials and store JWT
 * @param {Credentials} [credentials]
 * @throws No JWT found in auth call
 * @returns {Promise<any>} Valid JWT
 */
export const login = async credentials => {
  if (!credentials) return retrieveValidJWT()

  const result = await fetchJWT(credentials)
  const jwt = result?.data?.token

  if (!jwt) throw new Error('No JWT found in auth call')
  setJWT(jwt)
  return {
    jwt,
    jwtPayload: jsonwebtoken.decode(jwt)
  }
}

/**
 * Login or get stored JWT
 * Set authorization header
 * Decode jwt payload
 * Fetch current user
 * @param {Credentials} [credentials]
 * @returns {Promise<User>}
 */
export const authenticate = async credentials => {
  const { jwt } = await login(credentials)
  const authorizationHeader = getAuthorizationHeader(jwt)
  setAxiosHeader('Authorization', authorizationHeader)
  // TODO for API V1
  setAxiosHeaderApiV1('Authorization', authorizationHeader)

  const { data: currentUser } = await axios.get(endpoints.AUTH)

  return currentUser?.user
}

/**
 * User logout
 * Remove JWT and axios Authorization header
 */
export const logout = () => {
  removeJWT()
  removeAxiosHeader('Authorization')
}
