import axios from '@axios'
import endpoints from '@enums/endpoints'

/** @typedef {import('axios').AxiosPromise} AxiosPromise */

export default {
  /**
   * Fetch wordpress token values
   * @param {Object} params
   * @returns {AxiosPromise}
   */
  getWordpressTokens (params) {
    return axios.get(endpoints.WORDPRESS_TOKENS, { params })
  },
  /**
   * Fetch wordpress token debug values
   * @param {string} domain
   * @returns {AxiosPromise}
   */
  getWordpressTokensDebug (domain) {
    return axios.get(`${endpoints.WORDPRESS_TOKENS}/${domain}/debug`)
  }
}
