import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppWordpressIcon',
  className: 'app-wordpress',
  defaultAttrs: {
    viewBox: '0 0 50 50'
  },
  pathAttrs: [
    {
      d: 'M25 2C12.317 2 2 12.318 2 25s10.317 23 23 23 23-10.318 23-23S37.683 2 25 2zm0 5c4.26 0 8.166 1.485 11.247 3.955-.956.636-1.547 1.74-1.547 2.945 0 1.6.9 3 1.9 4.6.8 1.3 1.6 3 1.6 5.4 0 1.7-.5 3.8-1.5 6.4l-2 6.6-7.1-21.2c1.2-.1 2.3-.2 2.3-.2 1-.1.9-1.6-.1-1.6h-.1s-3.2.3-5.3.3c-1.9 0-5.2-.3-5.2-.3h-.1c-1 0-1.1 1.6-.1 1.6 0 0 1 .1 2.1.2l3.1 8.4L19.9 37l-7.2-21.4c1.2-.1 2.3-.2 2.3-.2 1-.1.9-1.6-.1-1.6h-.1s-2.152.202-4.085.274C14.003 9.78 19.168 7 25 7zM7 25c0-1.8.271-3.535.762-5.174l7.424 20.256C10.261 36.871 7 31.323 7 25zm12.678 17.2L25 26.6l5.685 15.471a17.992 17.992 0 0 1-11.007.129zm15.626-2.45L40.6 24.4c.786-2 1.21-3.742 1.39-5.304A17.944 17.944 0 0 1 43 25c0 6.111-3.046 11.497-7.696 14.75z'
    }
  ]
})
