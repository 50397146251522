export default Object.freeze({
  ACTION: 'action', // used for super_admin with aclObjects.ANY
  CREATE: 'create',
  DELETE: 'delete',
  GET: 'get',
  LIST: 'list',
  PUBLISH: 'publish',
  RESEND: 'resend',
  UPDATE: 'update'
})
