import views from '@enums/views'

const HomePage = () =>
  import(/* webpackChunkName: "homepage" */ '@views/HomePage.vue')

export default [
  {
    path: `/${views.HOMEPAGE}`,
    name: views.HOMEPAGE,
    component: HomePage,
    meta: {
      appBreadcrumb: [
        {
          text: 'dashboard',
          active: true
        }
      ]
    }
  }
]
