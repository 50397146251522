import axios from '@axios'
import endpoints from '@enums/endpoints'

/** @typedef {import('../store/modules/table').TableFiltersState} TableFiltersState */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */
/** @typedef {import('@store/modules/aclRules').Acl} Acl */

export default {
  /**
   * Fetch acl rules values
   * @param {TableFiltersState} params
   * @returns {AxiosPromise}
   */
  getAclRules (params) {
    return axios.get(endpoints.ACLS, { params })
  }
}
