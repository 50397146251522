import devices from '@enums/devices'

export default [
  {
    target: 'adf',
    id: 'adf',
    label: 'adf',
    params: ['mid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'adf-outstream',
    id: 'adf-outstream',
    label: 'adf-outstream',
    params: ['mid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [[640, 480]]
    }
  },
  {
    target: 'adot',
    id: 'adot',
    label: 'adot',
    params: ['placementId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600]
      ],
      video: [[640, 480]],
      native: [[1, 1]]
    }
  },
  {
    target: 'adyoulike',
    id: 'adyoulike',
    label: 'adyoulike',
    params: ['placement'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]],
      native: [[1, 1]]
    }
  },
  {
    target: 'appnexus',
    id: 'appnexus',
    label: 'appnexus',
    params: ['placement_id'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [1800, 1000],
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [320, 480],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [
        [640, 480]
      ],
      native: [[1, 1]]
    }
  },
  {
    target: 'eplanning',
    id: 'eplanning',
    label: 'eplanning',
    params: ['ci'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [320, 480],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]]
    }
  },
  {
    target: 'grid',
    id: 'grid',
    label: 'grid',
    params: ['uid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'grid-native',
    id: 'grid-native',
    label: 'grid-native',
    params: ['uid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      native: [[1, 1]]
    }
  },
  {
    target: 'grid-outstream',
    id: 'grid-outstream',
    label: 'grid-outstream',
    params: ['uid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [
        [640, 480]
      ]
    }
  },
  {
    target: 'improvedigital',
    id: 'improvedigital',
    label: 'improvedigital',
    params: ['placementId', 'publisherId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]],
      native: [[1, 1]]
    }
  },
  {
    target: 'ix',
    id: 'ix',
    label: 'ix',
    params: ['siteId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]],
      native: [[1, 1]]
    }
  },
  {
    target: 'oftmedia',
    id: 'oftmedia',
    label: 'oftmedia',
    params: ['placementId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]],
      native: [[1, 1]]
    }
  },
  {
    target: 'onetag',
    id: 'onetag',
    label: 'onetag',
    params: ['pubId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [1800, 1000],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]]
    }
  },
  {
    target: 'openx',
    id: 'openx',
    label: 'openx',
    params: ['delDomain', 'unit'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]]
    }
  },
  {
    target: 'pubmatic',
    id: 'pubmatic',
    label: 'pubmatic',
    params: ['publisherId', 'adSlot'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [320, 480],
        [1800, 1000],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'pubmatic-native',
    id: 'pubmatic-native',
    label: 'pubmatic-native',
    params: ['publisherId', 'adSlot'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      native: [[1, 1]]
    }
  },
  {
    target: 'pubmatic-outstream',
    id: 'pubmatic-outstream',
    label: 'pubmatic-outstream',
    params: ['publisherId', 'adSlot'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [[640, 480]]
    }
  },
  {
    target: 'rise',
    id: 'rise',
    label: 'rise',
    params: ['org', 'placementId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'rise-outstream',
    id: 'rise-outstream',
    label: 'rise-outstream',
    params: ['org', 'placementId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [[640, 480]]
    }
  },
  {
    target: 'rubicon',
    id: 'rubicon',
    label: 'rubicon',
    params: ['accountId', 'siteId', 'zoneId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [320, 480],
        [1800, 1000],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]],
      native: [[1, 1]]
    }
  },
  {
    target: 'sharethrough-120-600',
    id: 'sharethrough-120-600',
    label: 'sharethrough-120-600',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[120, 600]]
    }
  },
  {
    target: 'sharethrough-160-600',
    id: 'sharethrough-160-600',
    label: 'sharethrough-160-600',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[160, 600]]
    }
  },
  {
    target: 'sharethrough-300-250',
    id: 'sharethrough-300-250',
    label: 'sharethrough-300-250',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[300, 250]]
    }
  },
  {
    target: 'sharethrough-300-50',
    id: 'sharethrough-300-50',
    label: 'sharethrough-300-50',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[300, 50]]
    }
  },
  {
    target: 'sharethrough-300-600',
    id: 'sharethrough-300-600',
    label: 'sharethrough-300-600',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[300, 600]]
    }
  },
  {
    target: 'sharethrough-320-100',
    id: 'sharethrough-320-100',
    label: 'sharethrough-320-100',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[320, 100]]
    }
  },
  {
    target: 'sharethrough-320-50',
    id: 'sharethrough-320-50',
    label: 'sharethrough-320-50',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[320, 50]]
    }
  },
  {
    target: 'sharethrough-336-280',
    id: 'sharethrough-336-280',
    label: 'sharethrough-336-280',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[336, 280]]
    }
  },
  {
    target: 'sharethrough-728-90',
    id: 'sharethrough-728-90',
    label: 'sharethrough-728-90',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[728, 90]]
    }
  },
  {
    target: 'sharethrough-970-250',
    id: 'sharethrough-970-250',
    label: 'sharethrough-970-250',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[970, 250]]
    }
  },
  {
    target: 'sharethrough-970-90',
    id: 'sharethrough-970-90',
    label: 'sharethrough-970-90',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[970, 90]]
    }
  },
  {
    target: 'sharethrough-native',
    id: 'sharethrough-native',
    label: 'sharethrough-native',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      native: [[1, 1]]
    }
  },
  {
    target: 'sharethrough-outstream',
    id: 'sharethrough-outstream',
    label: 'sharethrough-outstream',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [[640, 480]]
    }
  },
  {
    target: 'smartadserver',
    id: 'smartadserver',
    label: 'smartadserver',
    params: ['networkId', 'siteId', 'pageId', 'formatId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [1800, 1000],
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 480],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]],
      native: [[1, 1]]
    }
  },
  {
    target: 'smilewanted',
    id: 'smilewanted',
    label: 'smilewanted',
    params: ['zoneId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'smilewanted-instream',
    id: 'smilewanted-instream',
    label: 'smilewanted-instream',
    params: ['zoneId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [
        [640, 480]
      ]
    }
  },
  {
    target: 'smilewanted-native',
    id: 'smilewanted-native',
    label: 'smilewanted-native',
    params: ['zoneId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      native: [
        [1, 1]
      ]
    }
  },
  {
    target: 'smilewanted-outstream',
    id: 'smilewanted-outstream',
    label: 'smilewanted-outstream',
    params: ['zoneId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [
        [640, 480]
      ]
    }
  },
  {
    target: 'unruly',
    id: 'unruly',
    label: 'unruly',
    params: ['siteId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]]
    }
  }
]
