import views from '@enums/views'
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'

const ProfileTabs = () =>
  import(/* webpackChunkName: "profile-tabs" */ '@views/profile/ProfileTabs.vue')
const ProfileGeneral = () =>
  import(/* webpackChunkName: "profile-general" */ '@views/profile/ProfileGeneral.vue')
const ProfileEmail = () =>
  import(/* webpackChunkName: "profile-email" */ '@views/profile/ProfileEmail.vue')
const ProfilePassword = () =>
  import(/* webpackChunkName: "profile-password" */ '@views/profile/ProfilePassword.vue')
const ProfileAPITokens = () =>
  import(/* webpackChunkName: "profile-api-tokens" */ '@views/profile/ProfileAPITokens.vue')

const appBreadcrumb = [
  {
    text: 'myAccount',
    active: true
  }
]

export default [
  {
    path: '/profile',
    redirect: { name: views.PROFILE_GENERAL },
    name: views.PROFILE,
    component: ProfileTabs,
    children: [
      {
        path: 'general',
        name: views.PROFILE_GENERAL,
        component: ProfileGeneral,
        meta: { appBreadcrumb }
      },
      {
        path: 'email',
        name: views.PROFILE_EMAIL,
        component: ProfileEmail,
        meta: { appBreadcrumb }
      },
      {
        path: 'password',
        name: views.PROFILE_PASSWORD,
        component: ProfilePassword,
        meta: { appBreadcrumb }
      },
      {
        path: 'api-tokens',
        name: views.PROFILE_API_TOKENS,
        component: ProfileAPITokens,
        meta: {
          appBreadcrumb,
          action: aclActions.LIST,
          object: aclObjects.API_TOKEN
        }
      }
    ]
  }
]
