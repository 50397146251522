import isObject from '@utils/isObject'
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'
import configStatus from '@enums/configStatus'
import Vue from 'vue'

export const state = {
  item: null,
  lastLockedConfig: null
}

export const getters = {
  /**
   * Isolate in json website config, values for specific module
   * @param {Object} state
   * @returns {string|object}
   */
  jsonModule: state => (module, stringify = false) => {
    const json = state.item?.json

    return !stringify ? json?.[module] : JSON.stringify(json?.[module])
  },
  /**
   * Test is a website config can be editable in relation with its status
   * @param {Object} state
   * @param {Object} getters
   * @returns {boolean}
   */
  isEditable (state, getters) {
    if (!isObject(state.item)) {
      return false
    }

    return state.item?.status === configStatus.DRAFT && !getters.isLocked
  },
  /**
   * Return reason why the config is not editable
   * @param {*} state
   * @param {*} getters
   * @returns {String}
   */
  disabledReason (state, getters) {
    if (getters.isEditable) return null

    if (state.item?.status !== configStatus.DRAFT) return 'configurationNotDraft'
    return 'configurationLocked'
  },
  /**
   * Test if a website is locked by someone else than the active user
   * @param {Object} state
   * @param {Object} getters
   * @param {Object} rootState
   * @returns {boolean}
   */
  isLocked (state, getters, rootState) {
    return state.item.lockedBy && state.item.lockedBy?.['@id'] !== rootState.user?.userData?.['@id']
  },
  /**
   * Fullname lacked by
   * @param state
   * @returns {string|null}
   */
  lockedByName (state) {
    if (!state.item?.lockedBy) return null
    return `${state.item.lockedBy?.firstName} ${state.item.lockedBy?.lastName}`
  }
}

export const mutations = {
  setWebsiteConfig (state, websiteConfig) {
    state.item = websiteConfig
  },
  setLastLockedConfig (state, value) {
    state.lastLockedConfig = value
  }
}

/** @typedef {import('vuex').ActionContext<State>} ActionContext - Action context */

export const actions = {
  /**
   * Fetch website config values and put it into state.item
   * @param {ActionContext} context - Action context
   * @param {string} websiteConfigIri
   * @returns {Promise<*>}
   */
  async fetchWebsiteConfig ({ state, commit }, websiteConfigIri) {
    // Fetch config only one time
    if (state.item && state.item?.['@id'] === websiteConfigIri) return

    const { data } = await this.$services.websiteConfigsService.getWebsiteConfig(websiteConfigIri)
    // Keep data in store
    commit('setWebsiteConfig', data)
  },
  /**
   * Send a PATCH request and update item stored in state with the request response
   * @param {ActionContext} context - Action context
   * @param {string} itemIRI
   * @param {Object} data
   * @returns {Promise<*>}
   */
  async patchWebsiteConfig ({ commit }, { itemIRI, data }) {
    const { data: responseData } = await this.$services.websiteConfigsService.updateWebsiteConfig(itemIRI, data)
    // Keep data in store
    commit('setWebsiteConfig', responseData)
  },

  /**
   * Lock the current config if it is not locked
   * @returns {Promise<*>}
   */
  async lock ({ state, commit }) {
    if (state.lastLockedConfig
      || state.item?.status !== configStatus.DRAFT
      || state.item?.lockedBy
      || !Vue.prototype.$can(aclActions.UPDATE, aclObjects.WEBSITE_CONFIG_LOCK)) return

    await this.$services.websiteConfigsService.lockWebsiteConfig(state.item['@id'])
    commit('setLastLockedConfig', state.item['@id'])
  },

  /**
   * Unlock the current config if it is locked
   * @returns {Promise<*>}
   */
  async unlock ({ state, commit }) {
    if (!state.lastLockedConfig
      || state.item?.status !== configStatus.DRAFT
      || !Vue.prototype.$can(aclActions.UPDATE, aclObjects.WEBSITE_CONFIG_LOCK)) return

    const configId = state.lastLockedConfig
    commit('setLastLockedConfig', null)
    await this.$services.websiteConfigsService.lockWebsiteConfig(configId, false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
