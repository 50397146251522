import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import analytics from '@enums/analytics'
import { timezones } from '@assets/data/timezones'

dayjs.extend(timezone)

const dayjsFormat = 'YYYY-MM-DD'

export const state = {
  [analytics.TIMEZONE]: timezones.includes(dayjs.tz.guess()) ? dayjs.tz.guess() : analytics.TIMEZONE_DEFAULT,
  [analytics.CURRENCY]: 'EUR',
  [analytics.IS_CUSTOMER]: 1
}

export const getters = {
  startLastSevenDays () {
    return dayjs()
      .startOf('day')
      .subtract(7, 'day')
      .format(dayjsFormat)
  },
  startLastThirtyDays () {
    return dayjs()
      .startOf('day')
      .subtract(30, 'day')
      .format(dayjsFormat)
  },
  endLastDay () {
    return dayjs()
      .startOf('day')
      .subtract(1, 'day')
      .format(dayjsFormat)
  }
}

export const mutations = {
  set (state, { key, value }) {
    state[key] = value
  }
}

export const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
