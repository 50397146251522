const analyticPath = '/data/analytic'

export default Object.freeze({
  ACLS: '/acls',
  ADS_TXT: '/adstxt',
  API_TOKENS: '/api_tokens',
  API_URL: process.env.VUE_APP_API_URL,
  API_URL_V1: process.env.VUE_APP_API_URL_V1, // TODO for API V1
  AUTH: '/authentication/auth',
  AUTH_TOKEN: '/authentication_token',
  BIDDERS: '/bidders',
  CASBIN_POLICIES: '/casbin_policies',
  COUNTRIES: '/countries',
  CURRENCIES: '/currencies',
  DUPLICATED_WEBSITE_CONFIGS: '/duplicated_website_configs',
  FORGOTTEN_PASSWORD: '/forgotten_passwords',
  GENDERS: '/genders',
  ORGANIZATIONS: '/organizations',
  ORGANIZATION_EXCLUSIONS: '/organization_exclusions',
  ORGANIZATION_INVITATIONS: '/organization_invitations',
  ORGANIZATION_INVITATIONS_RESEND: '{{organizationInvitationIRI}}/resend',
  ORGANIZATION_TYPES: '/organization_types',
  RESET_PASSWORD: '/reset_passwords',
  ROLES: '/roles',
  UPDATE_EMAIL: '/updated_email_addresses',
  UPDATE_PASSWORD: '/updated_passwords',
  USER_GROUPS: '/user_groups',
  USER_GROUPS_NAMES: '/user_groups/names',
  USER_HAS_ACTION: '{{userIRI}}/has-action',
  USER_HAS_ORGANIZATIONS: '/user_has_organizations',
  USER_HAS_WEBSITES: '/user_has_websites',
  USERS: '/users',
  USERS_INVITATION: '/users/post-registration/{{invitationIRI}}',
  VALIDATE_REGISTER: '/validated_email_addresses',
  WEBSITE_ALLOWED_USERS: '/website_allowed_users',
  WEBSITE_CONFIGS: '/website_configs',
  WEBSITE_CONFIGS_ADS_TXT: '{{websiteConfigIRI}}/adstxt',
  WEBSITE_CONFIGS_LOCK: '{{websiteConfigIRI}}/lock',
  WEBSITE_CONFIGS_PUBLISH: '{{websiteConfigIRI}}/publish',
  WEBSITE_PRODUCTS: '/website_products',
  WEBSITES: '/websites',
  WEBSITES_ADS_TXT_MONITORING: '{{websiteIRI}}/adstxt_monitorings',
  WEBSITES_SCRAP: '{{websiteIRI}}/scrap',
  WORDPRESS_TOKENS: '/wordpress_tokens',

  // Analytics
  PAGE_VIEWS: `${analyticPath}/audiences/page_views`,
  PARTNER_REVENUE: `${analyticPath}/monetizations/partner_revenue`,
  RPM_SESSIONS: `${analyticPath}/monetizations/rpm_sessions`,
  SESSIONS: `${analyticPath}/audiences/sessions`
})
