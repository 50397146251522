import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import table from '@enums/table'
import websiteProducts from '@enums/websiteProducts'

/**
 * @typedef {Object} Websites
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {Array} hydraMember - as hydra:member
 * @prop {string} hydraMember.id - as hydra:member.@id
 * @prop {string} hydraMember.type - as hydra:member.@type
 * @prop {boolean} hydraMember.active - as hydra:member.active
 * @prop {string} hydraMember.domain - as hydra:member.domain
 * @prop {string} hydraMember.name - as hydra:member.name
 * @prop {string} hydraMember.organization - as hydra:member.organization
 * @prop {boolean} hydraMember.validated - as hydra:member.validated
 * @prop {number} hydraTotalItems - as hydra:totalItems
 * @prop {Object} hydraSearch - as hydra:search
 */

/**
 * @typedef {Object} Website
 * @props {number} actiriseFees
 * @prop {boolean} active
 * @prop {number} adserverFees
 * @prop {string} adserverFeesType
 * @prop {Array} allowedUsers
 * @prop {string} domain
 * @prop {number} externalFees
 * @prop {string} externalFeesType
 * @prop {number} marginFees
 * @prop {number} minimalTargetRpm
 * @prop {string} name
 * @prop {string} organization
 * @prop {Object} targetRpm
 * @prop {number} technicalFees
 * @prop {boolean} technicalFeesMode
 * @prop {string} uuid
 * @prop {boolean} validated
 */

/** @typedef {import('vuex').ActionContext<State>} ActionContext - Action context */
/** @typedef {import('./modules/table').TableFiltersState} TableFiltersState */

/**
 * @typedef {Object} State
 * @prop {Object} service
 * @prop {Websites[]} [items]
 * @prop {number} totalItems
 * @prop {TableFiltersState} tableFiltersState
 * @prop {boolean} [tableColumnFilters.active=true]
 * @prop {boolean,} [tableColumnFilters.validated=true]
 */

/** @type {State} */
export const state = {
  service: {
    name: 'websitesService',
    getItemsMethod: 'getWebsites',
    getItemMethod: 'getWebsite',
    patchItemMethod: 'updateWebsite'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      name: table.SORT_ASC
    }
  },
  websitesScraping: undefined,
  keepIRISerializedData: false
}

export const getters = {
  ...gettersTable,
  /**
   * @param {State} state
   * @returns {boolean}
   */
  activeWebsite (state) {
    return state.editedItem?.active
  },
  /**
   * @param {State} state
   * @returns {boolean}
   */
  flashbidProduct (state) {
    return [websiteProducts.SDK, websiteProducts.WORDPRESS_PLUGIN].includes(state.editedItem?.websiteProduct?.['@id'])
  }
}

export const mutations = {
  ...mutationsTable,
  /**
   * @param {State} state
   * @param {string} websitesScraping
   */
  setWebsitesScraping (state, websitesScraping) {
    state.websitesScraping = websitesScraping
  }
}

export const actions = {
  ...actionsTable,
  /**
   * Scrap websites
   * @param {ActionContext} context
   * @param {String} websiteIri
   * @param {Object} data
   * @returns {Promise<*>}
   */
  // eslint-disable-next-line no-empty-pattern
  async scrapWebsite ({ state, commit }, { websiteIri, data }) {
    // The request is made only once per session
    if (state.websitesScraping) {
      const scrapingData = JSON.parse(state.websitesScraping)
      if (scrapingData.includes(websiteIri)) return
      // Add website IRI in session storage
      scrapingData.push(websiteIri)
      commit('setWebsitesScraping', JSON.stringify(scrapingData))
    } else {
      commit('setWebsitesScraping', JSON.stringify([websiteIri]))
    }

    await this.$services.websitesService.scrapWebsite(websiteIri, data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
