// Key label match with optionLabels.positions i18n locales
export default Object.freeze({
  BR: {
    value: 'BR',
    label: 'optionLabels.positions.bottomRight'
  },
  BL: {
    value: 'BL',
    label: 'optionLabels.positions.bottomLeft'
  },
  TL: {
    value: 'TL',
    label: 'optionLabels.positions.topLeft'
  },
  TR: {
    value: 'TR',
    label: 'optionLabels.positions.topRight'
  }
})
