import views from '@enums/views'
import store from '@store'
import endpoints from '@enums/endpoints'
import statusCode from '@enums/statusCode'
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'

const BiddersList = () =>
  import(/* webpackChunkName: "bidders-list" */ '@views/bidders/BiddersList.vue')
const BiddersTabs = () =>
  import(/* webpackChunkName: "bidders-tabs" */ '@views/bidders/BiddersTabs.vue')
const BiddersManager = () =>
  import(/* webpackChunkName: "bidders-manager" */ '@views/bidders/BiddersManager.vue')

const appBreadcrumb = [
  {
    text: 'bidders',
    to: { name: views.BIDDERS }
  },
  {
    text: () => store.getters['bidders/editedItemName'],
    active: true
  }
]

export default [
  {
    path: `/${views.BIDDERS}`,
    name: views.BIDDERS,
    component: BiddersList,
    meta: {
      appBreadcrumb: [
        {
          text: 'bidders',
          active: true
        }
      ],
      action: aclActions.LIST,
      object: aclObjects.DEFAULT_BIDDERS
    }
  },
  {
    path: `/${views.BIDDERS}/create`,
    name: views.BIDDERS_CREATE,
    component: BiddersManager,
    props: {
      action: 'create'
    },
    meta: {
      appBreadcrumb: [
        {
          text: 'bidders',
          to: { name: views.BIDDERS }
        },
        {
          text: 'create',
          active: true
        }
      ],
      action: aclActions.CREATE,
      object: aclObjects.DEFAULT_BIDDERS
    }
  },
  {
    path: `/${views.BIDDERS}/edit/:id(\\d+)`,
    redirect: { name: views.BIDDERS_EDIT_GENERAL },
    name: views.BIDDERS_EDIT,
    component: BiddersTabs,
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('bidders/fetchItem', `${endpoints.BIDDERS}/${to.params.id}`)
        next()
      } catch (error) {
        error?.response?.status === statusCode.UNAUTHORIZED
          ? next(false)
          : next({
            name: views.RESOURCE_NOT_FOUND,
            params: { target: views.BIDDERS_EDIT }
          })
      }
    },
    children: [
      {
        path: '',
        name: views.BIDDERS_EDIT_GENERAL,
        component: BiddersManager,
        meta: {
          appBreadcrumb,
          action: aclActions.GET,
          object: aclObjects.DEFAULT_BIDDERS
        }
      }
    ]
  }
]
