// Key label match with utm i18n locales
export default Object.freeze({
  CAMPAIGN: {
    value: 'utm_campaign',
    label: 'utm.utm_campaign'
  },
  CONTENT: {
    value: 'utm_content',
    label: 'utm.utm_content'
  },
  MEDIUM: {
    value: 'utm_medium',
    label: 'utm.utm_medium'
  },
  SOURCE: {
    value: 'utm_source',
    label: 'utm.utm_source'
  }
})
