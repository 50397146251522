import axios, { getBounceUrl } from '@axios'
import endpoints from '@enums/endpoints'
import config from '@enums/config'

/** @typedef {import('../store/modules/user').LocalUser} User */
/** @typedef {import('../store/modules/user').APIUser} APIUser */
/** @typedef {User & {plainPassword: string}} UserRegister */
/**
 * @template T
 * @typedef {import('axios').AxiosPromise<T>} AxiosPromiseGeneric
 */
/** @typedef {import('axios').AxiosPromise} AxiosPromise  */
/** @typedef {AxiosPromiseGeneric<APIUser>} AxiosUserResponse */
/** @typedef {{id: string, token: string}} ValidationPayload */
/** @typedef {ValidationPayload & {plainPassword: string}} PasswordResetPayload */

/**
 * @typedef {Object} UpdateEmailPayload
 * @prop {string} payload.user - Current user IRI
 * @prop {string} payload.email - New user email
 * @prop {string} payload.password - Current user password
 */

/** @typedef {import('../store/modules/table').TableFiltersState} TableFiltersState */

/**
 * @typedef {Object} UserHasOrganizations
 * @prop {string} user
 * @prop {string} op (add or remove)
 * @prop {Array} organizations (id not IRI)
 */

/**
 * @typedef {Object} UserHasWebsites
 * @prop {string} user
 * @prop {string} op (add or remove)
 * @prop {Array} websites (id not IRI)
 */

const registrationValidationUrl = getBounceUrl('/validate')

const resetPasswordUrl = getBounceUrl('/reset-password')

export default {
  /**
   * User registration
   * @param {UserRegister} user
   * @param {string|null} [invitationIRI=null]
   * @returns {AxiosUserResponse}
   */
  register (user, invitationIRI = null) {
    const endpoint = invitationIRI
      ? endpoints.USERS_INVITATION.replace('{{invitationIRI}}', invitationIRI)
      : endpoints.USERS

    return axios.post(endpoint, user, {
      headers: {
        [config.BOUNCE_PATH]: registrationValidationUrl
      }
    })
  },
  /**
   * User registration validation
   * @param {ValidationPayload} payload
   * @returns {AxiosPromise}
   */
  validateRegister ({ id, token }) {
    return axios.post(endpoints.VALIDATE_REGISTER, {
      userId: Number(id),
      token
    })
  },
  /**
   * Request user password reset
   * @param {string} email
   * @returns {AxiosPromise}
   */
  requestPasswordReset (email) {
    return axios.post(
      endpoints.FORGOTTEN_PASSWORD,
      { email },
      {
        headers: {
          [config.BOUNCE_PATH]: resetPasswordUrl
        }
      }
    )
  },
  /**
   * User password change
   * @param {PasswordResetPayload} payload
   * @returns {AxiosPromise}
   */
  resetPassword ({ id, token, plainPassword }) {
    return axios.post(endpoints.RESET_PASSWORD, {
      plainPassword,
      userId: Number(id),
      token
    })
  },
  /**
   * User email update
   * @param {UpdateEmailPayload} payload
   * @returns {AxiosPromise}
   */
  updateEmail ({ user, email, password }) {
    return axios.post(endpoints.UPDATE_EMAIL, {
      user,
      email,
      password
    })
  },
  /**
   * User password update
   * @param {Object} payload
   * @param {string} payload.user - Current user IRI
   * @param {string} payload.currentPassword
   * @param {string} payload.newPassword
   * @returns {AxiosPromise}
   */
  updatePassword ({ user, currentPassword, newPassword }) {
    return axios.post(endpoints.UPDATE_PASSWORD, {
      user,
      currentPassword,
      newPassword
    })
  },
  /**
   * Get users data
   * @param {TableFiltersState} params
   * @returns {AxiosPromise}
   */
  getUsers (params) {
    return axios.get(endpoints.USERS, { params })
  },
  /**
   * Get user data
   * @param {string} userIRI
   * @returns {AxiosPromise}
   */
  getUser (userIRI) {
    return axios.get(userIRI)
  },
  /**
   * User has action (ACL)
   * @param {string} userIRI
   * @param {string} object
   * @param {string} action
   * @returns {AxiosPromise}
   */
  getUserHasAction (userIRI, { object, action }) {
    return axios.get(endpoints.USER_HAS_ACTION.replace('{{userIRI}}', userIRI), {
      params: {
        object,
        action
      }
    })
  },
  /**
   * Update user data
   * @param {string} userIRI
   * @param {User} data
   * @returns {AxiosPromise}
   */
  updateUser (userIRI, data) {
    return axios.patch(userIRI, data)
  },
  /**
   * Update user preferred locale
   * @param {string} userIRI
   * @param {string} locale
   */
  changePreferredLocale (userIRI, locale) {
    return axios.patch(userIRI, { preferredLocale: locale }).catch(error => error)
  },
  /**
   * Update (add, remove) organizations allowed to user
   * @param {UserHasOrganizations} data
   * @returns {AxiosUserResponse}
   */
  updateUserHasOrganizations (data) {
    return axios.post(endpoints.USER_HAS_ORGANIZATIONS, data)
  },
  /**
   * Update (add, remove) websites allowed to user
   * @param {UserHasWebsites} data
   * @returns {AxiosUserResponse}
   */
  updateUserHasWebsites (data) {
    return axios.post(endpoints.USER_HAS_WEBSITES, data)
  }
}
