// TODO for API V1
import axios from 'axios'
import qs from 'qs'
import endpoints from '@enums/endpoints'
import { i18n } from '@i18n'

const axiosInstance = axios.create({
  baseURL: endpoints.API_URL_V1,
  headers: {
    common: {
      Accept: 'application/ld+json, application/json',
      'Accept-Language': i18n.global.locale.value
    }
  },
  paramsSerializer: params => qs.stringify(params)
})

/**
 * @param {string} header
 * @param {string} locale
 */
export const setAxiosHeaderApiV1 = (header, locale) =>
  Reflect.set(axiosInstance.defaults.headers.common, header, locale)

export default axiosInstance
